<template>
  <div id="app">
    <PoolGames v-if="showPoolGames" />
  </div>
</template>

<script>

import PoolGames from './components/PoolGames.vue';

export default {
  name: 'App',
  data() {
    return {
      showPoolGames: false,
    }
  },
  components: {
    PoolGames
  },
  mounted() {
    // Lecture de l'UUID passé en paramètre
    let urlParams = new URLSearchParams(window.location.search);
    if ( ( urlParams.has('uuid') ) &&
         ( urlParams.get('uuid') !== '' ) ) {
      this.showPoolGames = true;
    }
  }
};

</script>

<style>

#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;
  padding: 5px;
}

</style>
