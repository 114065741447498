<template>
  <div>
    <div class="title">
      <strong>
        <span class="category">{{ poolData.category }}</span>
        <span class="pool">Poule {{ poolData.pool }}</span>
        <span class="court">Terrain {{ poolData.court }}</span>
      </strong>
    </div>

    <div class="games">
      <div v-for="(game, index) in poolData.games" :key="index" class="game">
        <button @click="activateGame(index)" :disabled="canEnable === false || game.validated || activeGameIndex !== null && activeGameIndex !== index">✏️</button>
        <span>{{ game.team1.name }}</span>
        <input v-model="game.team1.score" type="number" min="0" :disabled="enabledGames[index] === false" @focus="$event.target.select()">
        <input v-model="game.team2.score" type="number" min="0" :disabled="enabledGames[index] === false" @focus="$event.target.select()">
        <span>{{ game.team2.name }}</span>
        <button @click="validateGame(index)" :disabled="enabledGames[index] === false || game.validated">✔️</button>
      </div>
    </div>

    <div class="rankings">
      <div v-for="(team, index) in poolData.ranking" :key="index" class="ranking">
        <span class="index">{{ index + 1 }}</span>
        <span class="name">{{ team.name }}</span>
        <span class="points">{{ team.points }}</span>
        <span class="ratio">{{ team.ratio }}</span>
      </div>
    </div>

  </div>
</template>

<script>

const WS_URL = 'wss://tournois.poules.wss.home.nigoflo83.ovh';

export default {
  data() {
    return {
      ws: null,
      poolData: {
        category: '',
        pool: 1,
        court: 1,
        ranking: [],
        games: []
      },
      activeGameIndex: null,
      countdown: '',
      gameStatus: 'Match en cours',
      enabledGames: [ false, false, false, false, false, false ],
      canEnable: false,
      localAddress: '',
      uuid: '',
      owner: ''
    };
  },
  methods: {
    activateGame(index) {
      // Si le match n'est pas activé
      if ( this.activeGameIndex !== index ) {
        // On devient propriétaire
        this.owner = this.localAddress;
        const messageData = { type: "SET_OWNER", uuid: this.uuid, owner: this.localAddress };
        this.ws.send(JSON.stringify(messageData, null, 2));
        
        // On active la ligne
        this.activeGameIndex = index;
        this.enabledGames[index] = true;
      }
      
      // Sinon, le match est activé
      else {
        // On n'est plus propriétaire
        this.owner = '';
        const messageData = { type: "SET_OWNER", uuid: this.uuid, owner: '' };
        this.ws.send(JSON.stringify(messageData, null, 2));
        
        // On désactiveactive la ligne
        this.activeGameIndex = null;
        this.enabledGames[index] = false;
      }
    },
    
    incrementScore(team, gameIndex, teamIndex) {
      team.score++;
      
      // Envoi du nouveau score au serveur
      if ( teamIndex === 1 ) {
        this.poolData.games[gameIndex].team1.score = team.score;
      }
      else {
        this.poolData.games[gameIndex].team2.score = team.score;
      }
      const messageData = { type: "MODIFY_GAMES", uuid: this.uuid, games: this.poolData.games };
      this.ws.send(JSON.stringify(messageData, null, 2));
    },
    
    decrementScore(team, gameIndex, teamIndex) {
      if ( team.score > 0 ) {
        team.score--;
      }
      
      // Envoi du nouveau score au serveur
      if ( teamIndex === 1 ) {
        this.poolData.games[gameIndex].team1.score = team.score;
      }
      else {
        this.poolData.games[gameIndex].team2.score = team.score;
      }
      const messageData = { type: "MODIFY_GAMES", uuid: this.uuid, games: this.poolData.games };
      this.ws.send(JSON.stringify(messageData, null, 2));
    },
    
    validateGame(index) {
      // Validation du match
      this.poolData.games[index].validated = true;

      // On désactiveactive la ligne
      this.activeGameIndex = null;
      this.enabledGames[index] = false;
      
      const messageData = { type: "MODIFY_GAMES", uuid: this.uuid, games: this.poolData.games };
      this.ws.send(JSON.stringify(messageData, null, 2));
    },
    
    updateCanEnableFlag() {
      if ( ( this.localAddress !== '' ) && 
           ( ( this.owner === '' ) || 
             ( this.owner === this.localAddress ) ) ) {
        this.canEnable = true;
      }
      else {
        this.canEnable = false;
      }
      console.log(this.canEnable);
    },
    
    tryToSendUUIDDataRequest() {
      if ( this.localAddress !== '' ) {
        const messageData = { type: "GET_DATA", uuid: this.uuid, address: this.localAddress };
        this.ws.send(JSON.stringify(messageData, null, 2));
      }
      else {
        setTimeout(this.tryToSendUUIDDataRequest, 2000);
      }
    },
    
    tryToConnect() {
      // Initialisation de la websocket
      if ( ( this.ws === null ) ||
           ( this.ws.readyState !== 1 ) ) {
        console.log('Trying to connect to the server ' + WS_URL);
        
        this.ws = new WebSocket(WS_URL);

        this.ws.onmessage = (message) => {
          let receivedData = JSON.parse(message.data);

          // Traitement d'une réception des données
          if ( receivedData.type === 'DATA' ) {
            this.poolData = receivedData.poolData;
            this.owner    = receivedData.owner;
            this.updateCanEnableFlag();
          }
        };
        
        this.ws.onopen = () => {
          console.log('Connected to the server');
          
          setTimeout(this.tryToSendUUIDDataRequest(), 1000);
        };
        
        this.ws.onclose = () => {
          console.log('Disconnected from the server');
          this.canEnable = false;
          this.activeGameIndex = null;

          setTimeout(this.tryToConnect, 2000);
        };
      }
    },
    
    findLocalAddress() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          this.localAddress = data.ip;
          console.log(this.localAddress);
          this.updateCanEnableFlag();
        })
        .catch(error => {
          console.error('Error fetching IP : ', error);
        });
    }
  },
  
  mounted() {
    // Lecture de l'UUID passé en paramètre
    let urlParams = new URLSearchParams(window.location.search);
    if ( urlParams.has('uuid') ) {
      this.uuid = urlParams.get('uuid');
    }
    
    if ( this.uuid != '' ) {
      // Connection
      this.tryToConnect();
    
      // Identifier le client par une adresse
      this.findLocalAddress();
    }
  }
};

</script>

<style>

.title {
  #width: 100%;
  color: #fff;
  background: #369ff4;
  padding: 4px;
  margin-bottom: 20px;
}

.category {
  margin-right: 20px;
}

.pool {
  margin-left: 20px;
  margin-right: 20px;
}

.court {
  margin-left: 20px;
}

.games {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
}

.game {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.game span,
.game input,
.game button {
  margin-right: 5px;
}

.game span {
  min-width: 85px;
  max-width: 85px;
}

.game input {
  width: 20px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.rankings {
  visibility: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.ranking {
  display: inline-block;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.index {
  display: inline-block;
  margin-right: 20px;
  width:30px;
}

.name {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  width:40%;
}

.points {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  width:30px;
}

.ratio {
  display: inline-block;
  margin-left: 20px;
  width:40px;
}


</style>
